<template>
    <div class="layout_index">
        <div class="flex_end">
            <span class="collection" @click="collect()">收藏网站</span>
        </div>

        <div class="collection_container">
        <div class="flex_between">
        <div class="collection_title flex_start">
            <img src="../../utils/img/collect_logo.png" class="collection_logo" />
            <span>快捷<br />导航</span>
        </div>

        <div  class="search_input">
        <el-input
      v-model="searchVal"
      placeholder="请输入搜索内容"
    >
      <template #append>
      <img class="search_logo" src="../../utils/img/search_logo.png" @click="searchNav()" />
      </template>
    </el-input>
      </div>
        </div>

 
          <div class="swiper_container">
        <div class="swiper-container swiper1" >
        <div class="swiper-wrapper">
          <div class="swiper-slide ">
            <div class="swiper1_item">
            <template v-for="(item,index) in navList.page1" :key="index">
              <div class="page_item flex_center" @click="linkNav(item)">
                <img class="page_bgImg" :src="item.bgImg" />
                <div class="flex_center">

            

                  <div class="wx_code" v-if="item.native">
                    <img  :src="item.afterImg" />
                  </div>

                  <img class="page_logo" v-else  :src="item.logo" />


                <div class="page_title">
                    <span>{{item.native?'打开微信，扫一扫': item.title }}</span>
                    <span class="page_subTitle" v-if="!item.native">{{ item.subTitle }}</span>
                </div>
            </div>
              </div>
            </template>
        </div>
          </div>
          <div class="swiper-slide">
            <div class="swiper1_item">
            <template v-for="(item,index) in navList.page2" :key="index">
              <div class="page_item  flex_center"  @click="linkNav(item)">
                <img class="page_bgImg" :src="item.bgImg" />
                <div class="flex_center">
                <img class="page_logo"  :src="item.logo" />
                <div class="page_title">
                    <span>{{ item.title }}</span>
                    <span class="page_subTitle" >{{ item.subTitle }}</span>
                </div>
            </div>
              </div>
            </template>
            </div>

          </div>
   
        </div>
      
 
        <div class="swiper-button-prev"></div>
        <!--左箭头。如果放置在swiper-container外面，需要自定义样式。-->
        <div class="swiper-button-next"></div>
        <!--右箭头。如果放置在swiper-container外面，需要自定义样式。-->
      </div>
    </div>
    </div>

      </div>

  </template>
  
  <script>
  import { reactive, ref,onMounted} from "vue";
  import { useRouter } from 'vue-router';



  import Swiper, {
  Autoplay,
  EffectCoverflow,
  EffectCube,
  Pagination,
  Navigation,
} from "swiper";
Swiper.use([Autoplay, EffectCoverflow, EffectCube, Pagination, Navigation]);
// swiper.less/sass/css 决定了基础的样式
import "swiper/swiper.min.css";



  export default {
    name: "index",
    props: {},
    setup() {
  
     const router=useRouter()

     let searchVal=ref('')
     
  //收藏当前地址栏
    const collect=()=>{
        let currentRouter=router.currentRoute.value
        try {
        window.external.addFavorite(window.location.href, currentRouter.meta.title);
    }
    catch (e) {
        try {
            window.sidebar.addPanel(currentRouter.meta.title,window.location.href, "");
        }
        catch (e) {
            alert("抱歉，您所使用的浏览器无法完成此操作。\n\n加入收藏失败，请使用Ctrl+D进行添加");
        }
    }
        }

        const searchNav=()=>{
            let urls=searchVal.value?'https://www.baidu.com/s?wd='+searchVal.value:'https://www.baidu.com'
            window.open(urls)
        }



        let navList=reactive({page1:[
        {title:'蚂蚁客户营销管理系统',subTitle:'Ant customer marketing management system',logo:require('../../utils/img/icon_1.png'),bgImg:require('../../utils/img/bg_1.png'),afterImg:require('../../utils/img/icon_1_active.png'),native:false},
        {title:'蚂蚁售电需求申报系统',subTitle:'Ant electricity demand declaration system',logo:require('../../utils/img/icon_2.png'),bgImg:require('../../utils/img/bg_2.png'),nav:'https://shenbao.antgv.com/'},
        {title:'蚂蚁需求响应申报系统',subTitle:'Ant demand response declaration system',logo:require('../../utils/img/icon_8.png'),bgImg:require('../../utils/img/bg_8.png'),nav:'https://demand.antgv.com/'},
        {title:'蚂蚁需求响应分析系统',subTitle:'Ant demand response analysis system',logo:require('../../utils/img/icon_7.png'),bgImg:require('../../utils/img/bg_7.png'),nav:'https://background.antgv.com/#/?kw=nav_1'},
        {title:'蚂蚁营销辅助服务系统',subTitle:'Ant marketing auxiliary service system',logo:require('../../utils/img/icon_5.png'),bgImg:require('../../utils/img/bg_5.png'),afterImg:require('../../utils/img/assist_code_active.jpg'),native:false}, //nav:'https://background.antgv.com/#/?kw=nav_2'
        {title:'蚂蚁售后运营管理系统',subTitle:'Ant intelligent charging system',logo:require('../../utils/img/icon_10.png'),bgImg:require('../../utils/img/bg_10.png'),nav:'https://background.antgv.com/#/?kw=nav_3'},
        {title:'蚂蚁光伏发电智控系统',subTitle:'Ant intelligent charging system',logo:require('../../utils/img/icon_11.png'),bgImg:require('../../utils/img/bg_11.png'),nav:'https://gf.antgv.com/#/login' },
        {title:'蚂蚁智慧充电系统',subTitle:'Ant intelligent charging system',logo:require('../../utils/img/icon_4.png'),bgImg:require('../../utils/img/bg_4.png'),afterImg:require('../../utils/img/charging_active.jpg'),native:false},
        
        {title:'智慧充电站运营平台',subTitle:'Ant smart charging station operation platform',logo:require('../../utils/img/icon_9.png'),bgImg:require('../../utils/img/bg_9.png'),nav:'http://myjgnykj.evking.cn'},//https://cdz.antgv.com/
       
       
         
        ],page2:[
        {title:'电能交易分析系统',subTitle:'Ant Power Trading Analysis System',logo:require('../../utils/img/icon_6.png'),bgImg:require('../../utils/img/bg_6.png'), nav:'https://transaction.antgv.com'},//nav:'https://transaction.antgv.com/' 

        {title:'蚂蚁负荷预测分析系统',subTitle:'Ant load prediction and analysis system',logo:require('../../utils/img/icon_3.png'),bgImg:require('../../utils/img/bg_3.png'),nav:'https://forecast.antgv.com'},
        // {title:'打开微信，扫一扫',subTitle:'',logo:require('../../utils/img/icon_11.png'),bgImg:require('../../utils/img/bg_1.png')},

        ]})

        const linkNav=(item)=>{

         
              if(item.nav){
                window.open(item.nav)
              }
          
              if(item.afterImg){
              
                navList.page1.forEach(val => {
                    console.log(val)
                    if(val.afterImg==item.afterImg){
                         val.native=!val.native
                    }
                });
          
              }
        }

        onMounted(()=>{
      
           new Swiper(".swiper1", {
   
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          hideOnClick: true,
        },
        loop: true,
        autoplay:false,
      
     
      });


        })


      return {
        collect,
        searchVal,
        searchNav,
        navList,
        linkNav
       
      };
    },
  };
  </script>
  
  <style lang="scss" scoped>
//   @import url("../../../node_modules/swiper/swiper.scss");
   .layout_index{
     padding: 40px 56px;

     .collection_container{
      padding: 0  240px;
      box-sizing: border-box;
   
     }
     .swiper_container{
        padding: 56px 53px;
        background: rgba(255, 255, 255, 1);
      
        border-radius: 16px;
        margin-top: 39px;
        height: 100%;
     }
     .swiper1{
        width: 100%;
        height: 100%;
        min-height: 650px;
        overflow: hidden;
     
   
   
     }
     .swiper1 .swiper1_item{
        width: 100%;
        min-height: 650px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 54px;

     }
     .page_item{
        // width: 360px;
        height: 180px;
        padding: 0 22px;
        border-radius: 16px;
        box-sizing: border-box;
        position: relative;
        cursor: pointer;
     }
     .page_title{
        display: flex;
        flex-direction: column;
        font-size: 20px;
        font-weight: 500;
        color: rgba(36, 41, 71, 1);
        
        .page_subTitle{
        margin-top: 4px;
        font-size: 12px;
        font-weight: 500;
        color: rgba(91, 102, 120, 1);
        // word-break: break-all;


        }


     }
   .page_logo{
    width: 62px;
    height: 62px;
    margin-right: 16px;
   }
   .page_bgImg{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

   }
   .wx_code{
    width: 144px;
    height: 144px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  
    // padding: 20px;
   }
   .wx_code>img{
    width: 124px;
    height: 124px;
   
   }
   .wx_page{
    padding: 26px 22px;
   }
     .collection{
        font-size: 18px;
        font-weight: 500;
        color: rgba(36, 41, 71, 1);
       margin-bottom: 50px;
       cursor: pointer;

     }
     .collection_title{
        /** 文本1 */
        font-size: 24px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
    .collection_logo{
        width: 79px;
        height: 79px;
        margin-right: 16px;

     }

     }
    .search_logo{
     width: 36px;
     height: 30px;
     margin: 6px 0;
     }
     .search_input{
        width: 564px;
      
        :deep(.el-input__wrapper){
             border-top-left-radius: 48px;
             border-bottom-left-radius:48px;
             box-shadow:none;
           
            border: 1px solid rgba(79, 129, 255, 1);   
            border-right: none;         
            padding-left: 16px;
        }
        :deep(.el-input-group__append){
            border-top-right-radius: 48px;
             border-bottom-right-radius:48px; 
             border: 1px solid rgba(79, 129, 255, 1);    
             border-left: none;
             background: #ffffff;
             
        }
     }

   }
  </style>
  